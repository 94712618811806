import { useSearchParams, Navigate } from "react-router-dom";
import React from "react";

const Redirect = () => {
  const [searchParams] = useSearchParams();
  console.log(searchParams.get("code"));

  return (
    <Navigate to={"/register?code=" + searchParams.get("code")}></Navigate>
  );
};

export default Redirect;
