import "./admin.scss";
import React, { useEffect, useState } from "react";
import Header from "./partials/Header";
import { getAdminApiInstance } from "../utils/helper";
import {
  RegistrantTicket,
  EventData,
  ReportGenderData,
  ReportCgData,
} from "../interfaces/interfaces";
import moment from "moment";
import DataTable, { Alignment } from "react-data-table-component";
import Swal from "sweetalert2";
import { utils, writeFileXLSX } from "xlsx";
import axios from "axios";

function AdminEventDetail() {
  const [registrants, setRegistrants] = useState([] as RegistrantTicket[]);
  const [eventData, setEventData] = useState([] as EventData[]);
  const [reportCgData, setReportCgData] = useState([] as ReportCgData[]);
  const [reportGenderData, setReportGenderData] = useState(
    [] as ReportGenderData[]
  );

  //   init
  useEffect(() => {
    const getData = async () => {
      try {
        const instance = getAdminApiInstance();
        const apiResult = await instance.get("admin/registrants");
        setRegistrants(apiResult.data.result);

        const getQuota = await axios.get(
          process.env.REACT_APP_BE_URL +
            "/events/getquota/1?token=fAh2CcPumo4cd_SvsLdXPLT9yIZCqxwaY57d29ROCAc"
        );
        setEventData(getQuota.data.data);

        const getReportCgData = await axios.get(
          process.env.REACT_APP_BE_URL +
            "/events/getreportpercg/1?token=fAh2CcPumo4cd_SvsLdXPLT9yIZCqxwaY57d29ROCAc"
        );
        setReportCgData(getReportCgData.data.data);

        const getReportGenderData = await axios.get(
          process.env.REACT_APP_BE_URL +
            "/events/getreportpergender/1?token=fAh2CcPumo4cd_SvsLdXPLT9yIZCqxwaY57d29ROCAc"
        );
        setReportGenderData(getReportGenderData.data.data);
      } catch (exc: any) {
        Swal.fire("Error", "Something went wrong!", "error");
      }
    };
    getData();
  }, []);

  // schema column
  const columns = [
    {
      name: "Location",
      selector: (row: EventData) => row.name,
      sortable: true,
    },
    {
      name: "Quota",
      selector: (row: EventData) =>
        row.verified + row.waiting + " / " + row.quota,
      wrap: true,
    },
    {
      name: "Verified",
      selector: (row: EventData) => row.verified,
      sortable: true,
    },
    {
      name: "Registered",
      selector: (row: EventData) => row.waiting,
      sortable: true,
    },
    {
      name: "Expired",
      selector: (row: EventData) => row.expired,
      sortable: true,
    },
  ];
  const columnspercg = [
    {
      name: "Location",
      selector: (row: ReportCgData) => row.name,
      sortable: true,
    },

    {
      name: "AOG YOUTH",
      selector: (row: ReportCgData) => row.aog_youth_data,
      sortable: true,
    },
    {
      name: "AOG TEENS",
      selector: (row: ReportCgData) => row.aog_teens_data,
      sortable: true,
    },
    {
      name: "Others",
      selector: (row: ReportCgData) => row.others_data,
      sortable: true,
    },
  ];
  const columnspergender = [
    {
      name: "Location",
      selector: (row: ReportGenderData) => row.name,
      sortable: true,
    },

    {
      name: "Male",
      selector: (row: ReportGenderData) => row.male_data,
      sortable: true,
    },
    {
      name: "Female",
      selector: (row: ReportGenderData) => row.female_data,
      sortable: true,
    },
  ];

  //   modul export
  const handleExportData = async () => {
    const getExportableData = await axios.get(
      process.env.REACT_APP_BE_URL +
        "/events/1/get_exportable_data?token=fAh2CcPumo4cd_SvsLdXPLT9yIZCqxwaY57d29ROCAc"
    );
    const items = getExportableData.data.data;
    // generate excel
    const worksheet = utils.json_to_sheet(
      items.map((f: any) => {
        return {
          gms_id: f.gms_id,
          bill_no: f.bill_no,
          parent_fullname: f.parent_fullname,
          parent_phone: f.parent_phone,
          parent_email: f.parent_email,
          parent_gender: f.parent_gender,
          church: f.church,
          address: f.address,
          city: f.city,
          district: f.district,
          province: f.province,
          postal_code: f.postal_code,
          child_name: f.child_name,
          child_gender: f.child_gender,
          child_phone: f.child_phone,
          child_grade: f.child_grade,
          registered_at: f.created_at,
          status: f.status,
        };
      })
    );
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, "Sheet1");
    // create file download
    const filename =
      "export - " +
      moment().utc().add(7, "hours").format("YYYYMMDD HHmmss") +
      ".xlsx";
    worksheet["!cols"] = [{ wch: 12 }, { wch: 20 }];
    writeFileXLSX(workbook, filename);
  };

  return (
    <div>
      <Header currentPage={"admin/event_detail"} />
      <div id="mainAdmin">
        {/* content */}
        <div className="app-content content" style={{ paddingBottom: "50px" }}>
          <div className="content-wrapper">
            <div className="content-header row">
              <div className="content-header-left">
                <div className="breadcrumbs-top">
                  <div className="col-12">
                    <h2 className="content-header-title">
                      Event Detail
                      <button
                        style={{ marginLeft: "20px" }}
                        className="btn btn-primary"
                        onClick={handleExportData}
                      >
                        Export Excel
                      </button>
                    </h2>
                  </div>
                </div>
              </div>
            </div>
            <div className="content-body">
              <div className="mb-2 mt-2"></div>
              <div className="card">
                <DataTable
                  columns={columns}
                  data={eventData}
                  striped
                  pagination
                  responsive
                  subHeader
                  subHeaderAlign={Alignment.RIGHT}
                />
              </div>
            </div>
          </div>
          <div className="content-wrapper">
            <div className="content-header row">
              <div className="content-header-left">
                <div className="breadcrumbs-top">
                  <div className="col-12">
                    <h2 className="content-header-title">
                      Report PAID data per CG
                    </h2>
                  </div>
                </div>
              </div>
            </div>
            <div className="content-body">
              <div className="mb-2 mt-2"></div>
              <div className="card">
                <DataTable
                  columns={columnspercg}
                  data={reportCgData}
                  striped
                  pagination
                  responsive
                  subHeader
                  subHeaderAlign={Alignment.RIGHT}
                />
              </div>
            </div>
          </div>
          <div className="content-wrapper">
            <div className="content-header row">
              <div className="content-header-left">
                <div className="breadcrumbs-top">
                  <div className="col-12">
                    <h2 className="content-header-title">
                      Report PAID data per Gender
                    </h2>
                  </div>
                </div>
              </div>
            </div>
            <div className="content-body">
              <div className="mb-2 mt-2"></div>
              <div className="card">
                <DataTable
                  columns={columnspergender}
                  data={reportGenderData}
                  striped
                  pagination
                  responsive
                  subHeader
                  subHeaderAlign={Alignment.RIGHT}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminEventDetail;
